html, body, #root{
    height: 100%;
}

#app {
    background-color: #DDE6ED;
    min-height: 100%;
}

.p-menuitem, .p-menuitem-link {
    min-height: 50px;
    border-radius: 0px;
}

.p-menuitem-text, .p-menuitem-icon {
    color: #DDE6ED;
}

.p-menuitem-link:hover .p-menuitem-text, .p-menuitem-link:hover .p-menuitem-icon {
    color: #526D82;
}

.body {
    padding: 3% 5% 4% 5%;
}

#footer {
    min-height: 50px;
    background-color: #526D82;
}

#loginBody {
    padding: 8% 25%
}

#loginPanel {
    height: 450px;
    border-radius: 6px;
    border: 2px #9DB2BF solid;
    background-color: #FFFFFF;
    padding: 75px 50px 50px 50px;
}

.p-panel-content {
    min-height: 600px;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.p-column-header-content{
    justify-content: center;
}

#overlay-play{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.p-password-input{
    width: 100%;
}